import React, {useEffect, useState} from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {Row, Col, Table, Typography} from "antd";
import GetAllUsers from "../Common/ApiCall/GetAllUsers";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";

const columns = [
  { title: 'Email', dataIndex: 'email', key: 'email' },
  { title: 'Token', dataIndex: 'token', key: 'token' },
  { title: 'Claimed At', dataIndex: 'claimedAt', key: 'claimedAt' },
];

const {Title} = Typography

const UsersTable = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  function getAllUsers() {
    GetAllUsers()
      .then(r => {
        if(r.success) {
          setUsers(r.data)
        } else {
          SendNotification(NotificationTypeEnum.Failure, "Something Went Wrong")
        }
        setLoading(false)
      })
  }

  useEffect(()=>getAllUsers(), [])

  if(loading) {
    return (
      <PlainContentWrapper>
        <Title>
          Loading...
        </Title>
      </PlainContentWrapper>
    )
  }

  return(
    <PlainContentWrapper>
      <Row align="center">
        <Col>
          <Table
            columns={columns}
            dataSource={users}
      	    pagination={{pageSize: 500}}
          />
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default UsersTable
