import { GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import authHeader from "./auth-header";
import {getPath} from "./api_url";

const EmailQuery = async (q) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(getPath("/search_email"), {
      q
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    "data": [
      "kumar1@gmail.com",
      "kumar2@gmail.com",
      "kumar3@gmail.com"
    ],
    "success":true,
    "errors":[]
  }
}

export default EmailQuery
