import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import authHeader from "./auth-header";
import {getPath} from "./api_url";

const DeleteUser = async (email) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(getPath("/delete"),
      {email}
      , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if(apiResponse.body) {
    return apiResponse.body
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data":{},
    "success":true,
    "errors":[]
  }
}

export default DeleteUser
