import React, {useState} from "react"
import {Form, Input, Button, Checkbox, Row, Col, Typography, Divider} from 'antd';
import ContentWrapper from "../Components/ContentWrapper";
import SignInForm from "./SignInForm";
import SignUp from "./SignUp";
import UserLogWrapper from "../Components/UserLogWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import SignInImage from '../Common/Images/login.png'
import Register from '../Common/Images/register.png'
import {Redirect} from "react-router";

const {Title} =Typography

const SignIn = ({isAuthed, handleAuthed}) => {
  const [tab, setTab] = useState(1);

  const image = tab ? SignInImage : Register
  const title = tab ? 'Welcome Back! Admin' : 'Come, Join us!'
  const formTitle = tab ? 'Sign In' : 'Sign Up'

  if(isAuthed) {
    return (
      <Redirect
        to={{pathname: '/'}}
      />
    )
  }

  return(
    <UserLogWrapper>

      <Row align="center">

        <Col md={{span: 12}} lg={{span: 16}}>
          <PlainContentWrapper>

            <Title>
              {title}
            </Title>

            <img
              width="100%"
              alt="image"
              src={image}
            />

          </PlainContentWrapper>
        </Col>

        <Col md={{span: 8}} >
          <ContentWrapper style={{height: '80%'}}>

            <Title>
              {formTitle}
            </Title>

            <Divider />

            {
              tab ?
                <SignInForm
                  setTab={setTab}
                  handleAuthed={handleAuthed}
                />
                :
                <SignUp
                  setTab={setTab}
                />
            }
          </ContentWrapper>
        </Col>
      </Row>
    </UserLogWrapper>
  )
}

export default SignIn