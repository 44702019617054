import {GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import authHeader from "./auth-header";
import {getPath} from "./api_url";

const GetDetailedUser = async (value) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(getPath("/user_info"),
      value
      , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    "data": {
      "jvzoo_transactions": [
        {
          "ctransreceipt": "transaction_id",
          "ccustname": "name",
          "ccuststate": "st",
          "ccustcc": "cc",
          "ccustemail": "user@gmail.com",
          "cproditem": "1234",
          "cprodtitle": "product title",
          "cprodtype": "type",
          "ctransaction": "transaction",
          "ctransaffiliate": "affiliate",
          "ctransamount": "1234",
          "ctranspaymentmethod": "PayPal",
          "ctransvendor": "vendor",
          "ctranstime": 1601420322,
          "cupsellreceipt": "receipt",
          "cvendthru": "vendthru",
          "caffitid": "afftid"
        }
      ],
      "signup_token": {
        "id": 1,
        "email": "user@gmail.com",
        "token": "5f5e1284-1b83045d-381a1c06",
        "referrer": "JVZOO",
        "referrerId": null,
        "ctransreceipt": "transaction_id",
        "claimedAt": "2020-09-21T18:21:04.368Z",
        "createdAt": "2020-09-21T18:21:04.368Z"
      },
      "members_area_permissions": [
        "WHITELABEL"
      ],
      "instagram_account": {
        "id": "123423423",
        "username": "_a_gryffindor",
        "profile_pic": "https://instagram.fdel28-1.fna.fbcdn.net/v/t51.2885-19/s150x150/97155133_606089819995191_1827690313104228352_n.jpg?_nc_ht=instagram.fdel28-1.fna.fbcdn.net&_nc_ohc=bRespz1qQAcAX92IZxn&oh=b36c3da42b9aab40f2386d57e095790e&oe=5F92D403"
      },
      "pending_upgrades": ["PREMIUM_TRAINING","PREMIUM_TRAINING"],
      "accounts_limit": 50,
      "accounts_used": 12,
      "subscription_type": "PREMIUM",
      "free_calls": 500,
      "detailed_calls": 90000,
      "detailed_calls_expiry_time": "2020-09-21T18:21:04.368Z"
    },
    "success": true,
    "errors": []
  }
}

export default GetDetailedUser
