import { GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError";
import authHeader from "./auth-header";
import {getPath} from "./api_url";

const GetAllUsers = async () => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(getPath("/get_all"), {}, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    "data": [
      {email: "kumar@gmail.com", token: "abcdefgh", claimedAt: "20-20-20"},
      {email: "kumar@gmail.com", token: "abcdefgh", claimedAt: "20-20-20"},
      {email: "kumar@gmail.com", token: "abcdefgh", claimedAt: "20-20-20"}
    ],
    "success":true,
    "errors":[]
  }
}

export default GetAllUsers
