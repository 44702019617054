import React from "react";
import {Layout} from "antd";

const {Content} = Layout

const PlainContentWrapper = ({children, marginless}) => {
  return(
    <Content
      className={`content-wrapper `+ (marginless ? 'content-wrapper-margin-less' : '')}
    >
      {children}
    </Content>
  )
}

export default PlainContentWrapper