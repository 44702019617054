import React, {useEffect, useState} from "react";
import Dashboard from "../Dashboard";
import {Route, Switch} from "react-router-dom";
import SignIn from "../SignIn";
import {PrivateRoute} from "../Components/PrivateRoute";

const Home = () => {
  const [isAuthed, setAuthed] = useState(false)

  function handleAuthed() {
    if(localStorage.getItem('authToken')) {
      setAuthed(true)
    }
  }

  useEffect(()=>handleAuthed(), [])

  return(
    <div>
      <Switch>

        <PrivateRoute
          path="/"
          exact
          isAuthed={isAuthed}
          Component={Dashboard}
        />

        <Route
          path="/sign-in"
          exact
        >
          <SignIn
            isAuthed={isAuthed}
            handleAuthed={handleAuthed}
          />
        </Route>

      </Switch>
    </div>
  )
}

export default Home