import React, {useState} from "react";
import {Button, Form, Input} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import GetCustomerDetails from "../Common/ApiCall/GetCustomerId";
import {Redirect} from "react-router-dom";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";

const layout = {
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    span: 24
  },
};

const SignInForm = ({setTab, handleAuthed}) => {

  const onFinish = values => {
    GetCustomerDetails(values.email, values.password)
      .then(r => {
        if(r.success) {
          if(r.data.token) {
            localStorage.setItem('authToken', r.data.token)
            handleAuthed()
          } else {
            SendNotification(NotificationTypeEnum.Failure, 'Bad Credentials')
          }
        } else {
          SendNotification(NotificationTypeEnum.Failure, 'Bad Credentials')
        }
      })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  if(localStorage.getItem('authToken')) {
    return(
      <Redirect to={{
        pathname: '/'
      }} />
    )
  }

  return(
    <Form
      {...layout}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input
          placeholder="Email"
          type="text"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
        />
      </Form.Item>


      <Form.Item {...tailLayout}>
        <div className="align-center">
          <Button className="dark-button" size="large" type="primary" htmlType="submit">
            LOGIN
          </Button>
{/*          &nbsp;&nbsp;&nbsp;
          <Button size="large" onClick={()=>setTab(0)}>
            SIGN UP
          </Button>*/}
        </div>
      </Form.Item>

{/*      <Form.Item>
        <div className="align-center">
          Forgot Password?
        </div>
      </Form.Item>*/}
    </Form>
  )
}

export default SignInForm