import React, {useEffect, useState} from "react";
import ContentWrapper from "../Components/ContentWrapper";
import {Typography, Button, Form, Switch, Tooltip, Input, Table, Popconfirm, Spin, Card, Avatar, Radio, InputNumber, Divider, List} from "antd";
import {CloseCircleOutlined, ReloadOutlined} from '@ant-design/icons'
import DatePicker from 'react-datepicker'
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import GetIgDataFromUsername from "../Common/ApiCall/GetIgDataFromUsername";
import ApiError from "../Common/Models/ApiError";
import ChangeInstagramAccount from "../Common/ApiCall/ChangeInstagramAccount";
import ChangeMembersAreaPermissions from "../Common/ApiCall/ChangeUserPermissions";
import ChangeSubscriptionTypeApi from "../Common/ApiCall/ChangeSubscriptionTypeApi";
import ChangePassword from "../Common/ApiCall/ChangePassword";
import GetDetailedUser from "../Common/ApiCall/GetDetailedUser";
import ChangeDetailedCalls from "../Common/ApiCall/ChangeDetailedCalls";
import ChangeDetailedCallsExpiryTime from "../Common/ApiCall/ChangeDetailedCallsExpiryTime";
import ChangeFreeCalls from "../Common/ApiCall/ChangeFreeCalls";
import ChangeWhitelabelKey from "../Common/ApiCall/ChangeWhitelabelKey";
import ChangeEmail from "../Common/ApiCall/ChangeEmail";
import DeleteUser from "../Common/ApiCall/DeleteUser";

const {Title} = Typography
const {Meta} = Card

function getStatus(item) {
  let s = "unprocessed"
  if(item.refunded) {
    s= "Refunded"
  } else if(item.conflict) {
    s = "Conflict"
  } else if(item.processed) {
    s = "Processed"
  }
  return s.toUpperCase()
}

const tableCols1 = [
  {
    title: 'Customer Name',
    dataIndex: 'ccustname',
    key: 'ccustname'
  },
  {
    title: 'State',
    dataIndex: 'ccuststate',
    key: 'ccuststate'
  },
  {
    title: 'Country Code',
    dataIndex: 'ccustcc',
    key: 'ccustcc'
  },
  {
    title: 'Email',
    dataIndex: 'ccustemail',
    key: 'ccustemail'
  },
  {
    title: 'Product Number',
    dataIndex: 'cproditem',
    key: 'cproditem'
  },
  {
    title: 'Product Title',
    dataIndex: 'cprodtitle',
    key: 'cprodtitle'
  },
  {
    title: 'Product Type',
    dataIndex: 'cprodtype',
    key: 'cprodtype'
  },
  {
    title: 'Action Taken',
    dataIndex: 'ctransaction',
    key: 'ctransaction'
  },
  {
    title: 'Status',
    render: (item) => (
      <p>
        {getStatus(item)}
      </p>
    ),
    key: 'ctranstime'+1
  }
]

const tablecols2 = [
  {
    title: 'Amount Paid',
    dataIndex: 'ctransamount',
    key: 'ctransamount'
  },
  {
    title: 'Payment Method',
    dataIndex: 'ctranspaymentmethod',
    key: 'ctranspaymentmethod'
  },
  {
    title: 'Transaction Vendor',
    dataIndex: 'ctransvendor',
    key: 'ctransvendor'
  },
  {
    title: 'JVZoo Payment ID',
    dataIndex: 'ctransreceipt',
    key: 'ctransreceipt'
  },
  {
    title: 'Parent Receipt Number Upsell',
    dataIndex: 'cupsellreceipt',
    key: 'cupsellreceipt'
  },
  {
    title: 'Affiliate on Transaction',
    dataIndex: 'ctransaffiliate',
    key: 'ctransaffiliate'
  },
  {
    title: 'Affiliate Tracking Id',
    dataIndex: 'caffitid',
    key: 'caffitid'
  },
  {
    title: 'Transaction Time',
    render: item=>(
      <p>
        {new Date(parseInt(item.ctranstime)*1000).toLocaleString('en-GB')}
      </p>
    ),
    key: 'ctranstime'
  },
  {
    title: 'Deleted At',
    render: item => (
      <p>
        {item.deletedAt ? new Date(Date.parse(item.deletedAt)).toLocaleString('en-GB') : null}
      </p>
    ),
    key: 'deletedAt'
  }
]

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SuccessNotif = (message = "User Updated") => {
  SendNotification(NotificationTypeEnum.Success, message)
}

const BadNotif = (res) => {
  let message = res.errors[0].message ? res.errors[0].message : 'Something Gone Wrong'
  SendNotification(NotificationTypeEnum.Failure, message)
}

const removeArrayItem = (arr, itemToRemove) => {
  return arr.filter(item => item !== itemToRemove)
}

const ShowUsers = ({user, email, setKey, setData, setEmail}) => {
  const [detailedCallsForm] = Form.useForm()
  const [freeCallsForm] =Form.useForm()
  const [emailForm] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const [accountsLimitForm] = Form.useForm()
  const [query, setQuery] = useState('')
  const [jv_data, setJv] = useState([])
  const [members_area_permissions, set_member_area_permissions] = useState([])
  const [loading, setLoading] = useState(false)
  const [detailedCallsChanged, setDetailedCallsChanged] = useState(false)
  const [freeCallsChanged, setFreeCallsChanged] = useState(false)
  const [accountLimitsChanged, setAccountLimitsChanged] = useState(false)
  const [igUser, setIgUser] = useState(null)
  const [isDisabled, setDisabled] = useState(false)
  const [expiryDate, setExpiryDate] = useState(null)
  const [changeEmail, setChangeEmail] = useState(false)
  const [new_email, set_new_email] = useState('')
  const [forceChangeVisible, setForceChangeVisible] = useState(false)


  function RefreshData() {
    GetDetailedUser({email: email})
      .then(r => {
        setData(r.data)
        let mail
        if(r.data.signup_token) {
          mail = (r.data.signup_token.email)
          setDisabled(!user.signup_token.claimedAt)
        } else {
          setDisabled(true)
          mail = (r.data.jvzoo_transactions[0].ccustemail)
        }
        detailedCallsForm.setFieldsValue({detailed_calls: r.data.detailed_calls})
        freeCallsForm.setFieldsValue({free_calls: r.data.free_calls})
        accountsLimitForm.setFieldsValue({accounts_limit: r.data.accounts_limit})
        setExpiryDate(new Date(r.data.detailed_calls_expiry_time))
        passwordForm.resetFields()
        emailForm.setFieldsValue({email: mail})
      })
  }

  function changeMemberAreaPermissions (v) {
    setLoading(true)
    let callWhitelabel = false
    let permissions = members_area_permissions.slice()
    if(permissions.indexOf("WHITELABEL")===-1 && v["WHITELABEL"]) {
      callWhitelabel = true
    }
    for(let key in v) {
      if(v[key]) {
        permissions.push(key)
      } else {
        permissions = removeArrayItem(permissions, key)
      }
    }
    ChangeMembersAreaPermissions(email, permissions)
      .then(r => {
        if(r.success) {
          set_member_area_permissions(permissions)
          if(callWhitelabel) {
            ChangeWhitelabelKeyFunction({accounts_limit: 10})
          }
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  function ChangeUserType(value) {
    setLoading(true)
    ChangeSubscriptionTypeApi(email, value)
      .then(r => {
        if(r.success) {
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  function onDateChange(date) {
    setExpiryDate(date)
    setLoading(true)
    let diffDays = date - new Date(user.detailed_calls_expiry_time)
    diffDays = Math.ceil(diffDays / (1000 * 60 * 60 * 24))
    ChangeDetailedCallsExpiryTime(email, diffDays)
      .then(r => {
        if(r.success) {
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
      })
  }

  function changePassword(v) {
    setLoading(true)
    ChangePassword(email, v.password)
      .then(r => {
        if(r.success) {
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  function changeEmailFunction(v) {
    setLoading(true)
    ChangeEmail(email, v.email, false)
      .then(r => {
        if(r.success) {
          SuccessNotif()
          GetDetailedUser({email: v.email})
            .then(r => {
              setData(r.data)
              let mail
              if(r.data.signup_token) {
                mail = (r.data.signup_token.email)
                setDisabled(!user.signup_token.claimedAt)
              } else {
                setDisabled(true)
                mail = (r.data.jvzoo_transactions[0].ccustemail)
              }
              passwordForm.resetFields()
              setEmail(mail)
              emailForm.setFieldsValue({email: mail})
            })
          setChangeEmail(false)
        } else {
          BadNotif(r)
          if(r.errors[0].message === "Email already exists") {
            setForceChangeVisible(true)
            set_new_email(v.email)
          }
        }
        setLoading(false)
      })
  }

  function deleteAccountFunction() {
    setLoading(true)
    DeleteUser(email)
      .then(r => {
        if(r.success) {
          SuccessNotif("Account Removed")
          setKey(0)
        } else {
          BadNotif(r)
        }
        setLoading(false)
      })
  }

  function changeDetailedCalls(value) {
    setLoading(true)
    ChangeDetailedCalls(email, value.detailed_calls)
      .then(r => {
        if(r.success) {
          setDetailedCallsChanged(false)
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  function ChangeWhitelabelKeyFunction(value) {
    setLoading(true)
    ChangeWhitelabelKey(email, value.accounts_limit)
      .then(r => {
        if(r.success) {
          setAccountLimitsChanged(false)
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  function changeFreeCalls(value) {
    setLoading(true)
    ChangeFreeCalls(email, value.free_calls)
      .then(r => {
        if(r.success) {
          setFreeCallsChanged(false)
          SuccessNotif()
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  function SetJvData() {
    setJv(user.jvzoo_transactions)
  }

  function seedIgUser() {
    setIgUser(user.instagram_account)
  }

  function init() {
    seedIgUser()
    SetJvData()
    set_member_area_permissions(user.members_area_permissions ? user.members_area_permissions : [])
    setExpiryDate(new Date(user.detailed_calls_expiry_time))
    let mail
    if(user.signup_token) {
      mail = (user.signup_token.email)
      setDisabled(!user.signup_token.claimedAt)
    } else {
      setDisabled(true)
      mail = (user.jvzoo_transactions[0].ccustemail)
    }
    emailForm.setFieldsValue({email: mail})
  }

  useEffect(()=>{
    init()
  }, [])

  function handleVisibleChange(visible) {
    setForceChangeVisible(!visible)
  }

  function ForceChangeEmail() {
    setForceChangeVisible(false)
    setLoading(true)
    ChangeEmail(email, new_email, true)
      .then(r => {
        if(r.success) {
          SuccessNotif()
          GetDetailedUser({email: new_email})
            .then(r => {
              setData(r.data)
              let mail
              if(r.data.signup_token) {
                mail = (r.data.signup_token.email)
                setDisabled(!user.signup_token.claimedAt)
              } else {
                setDisabled(true)
                mail = (r.data.jvzoo_transactions[0].ccustemail)
              }
              passwordForm.resetFields()
              setEmail(mail)
              emailForm.setFieldsValue({email: mail})
            })
          setChangeEmail(false)
        } else {
          BadNotif(r)
        }
        setLoading(false)
      })
  }

  async function makeDetailedIDFromUsernames() {
    setLoading(true)
    let userData = await GetIgDataFromUsername(query)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      }
      setLoading(false)
      return
    }
    ChangeInstagramAccount(email,userData.id, userData.username, userData.profile_pic)
      .then(r => {
        if(r.success) {
          SuccessNotif()
          setIgUser(userData)
        } else {
          BadNotif(r)
        }
        setLoading(false)
        RefreshData()
      })
  }

  return(
    <ContentWrapper
      marginLess
    >
      <Title>
        Show User
        <Button onClick={()=>setKey(0)} style={{float: 'right'}} >
          Back
        </Button>
        <Tooltip title="Refresh User Data" >
          <Button shape="circle" onClick={RefreshData} style={{float: 'right', marginRight: '10px'}} >
            <ReloadOutlined
              style={{fontSize: '12px'}}
            />
          </Button>
        </Tooltip>
        <Divider />
      </Title>
        <Form
          hideRequiredMark
          labelCol={{span: 8}}
          wrapperCol={{span: 10}}
        >
          {loading ? (
            <Form.Item label="" >
              <Spin />
            </Form.Item>
          ) : null}
        </Form>

      <Form
        onFinish={changeEmailFunction}
        labelCol={{span: 8}}
        wrapperCol={{span: 10}}
        hideRequiredMark
        form={emailForm}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{
            required: true,
          }]}
        >
          <Input
            type="email"
            onChange={()=>setChangeEmail(true)}
            placeholder="Enter Email here to Change"
          />
        </Form.Item>
        {changeEmail && !forceChangeVisible ? (
          <Form.Item {...tailLayout} shouldUpdate={true}>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
              >
                Change Email
              </Button>
            )}
          </Form.Item>
        ): null}
        {changeEmail &&forceChangeVisible ? (
          <Form.Item {...tailLayout} shouldUpdate >
            <Popconfirm
              title={"Do You want to force Change the Email"}
              visible={forceChangeVisible}
              onVisibleChange={handleVisibleChange}
              onCancel={()=>setForceChangeVisible(false)}
              onConfirm={ForceChangeEmail}
              okText={"Yes"}
              cancelText={"No"}
            >
              <Button
                type="primary"
                htmlType="submit"
              >
                Change Email
              </Button>
            </Popconfirm>
          </Form.Item>
        ) : null}
      </Form>

          <Form
            {...layout}
            onValuesChange={changeMemberAreaPermissions}
          >
            <Form.Item
              name="WHITELABEL"
              label="Whitelabel"
            >
              <Switch
                disabled={isDisabled}
                checked={members_area_permissions.indexOf("WHITELABEL")!==-1}
              />
            </Form.Item>

            <Form.Item
              name="PREMIUM_TRAINING"
              label="Premium Training"
            >
              <Switch
                disabled={isDisabled}
                checked={members_area_permissions.indexOf("PREMIUM_TRAINING")!==-1}
              />
            </Form.Item>

            {members_area_permissions.indexOf("WHITELABEL")!==-1 ? (
                <Form
                  form={accountsLimitForm}
                  hideRequiredMark
                  onFinish={ChangeWhitelabelKeyFunction}
                  {...layout}
                >
                  <Form.Item
                    label="Whitelabel Accounts Limit"
                    name="accounts_limit"
                    rules={[{required: true, type: "number", min: 0}]}
                    initialValue={user.accounts_limit}
                  >
                    <InputNumber
                      disabled={isDisabled}
                      onChange={(e)=> {
                        if(e !== parseInt(e)) {
                          setAccountLimitsChanged(false)
                        } else {
                          setAccountLimitsChanged(true)
                        }
                      }}
                    />
                  </Form.Item>
                  {accountLimitsChanged ? (
                    <Form.Item wrapperCol={{offset: 8,span: 16}}>
                      <Button
                        type="primary"
                        htmlType="submit"
                      >
                        Confirm
                      </Button>
                    </Form.Item>
                  ): null}
                </Form>
            ) : null}
          </Form>

      <Form
        hideRequiredMark
        {...layout}
      >
          <Form.Item
            name="SUBSCRIPTION_TYPE"
            label="Subscription Type"
            initialValue={user.subscription_type}
          >
            <Radio.Group
              disabled={isDisabled}
              value={user.subscription_type}
              onChange={e=>ChangeUserType(e.target.value)}
            >
              <Radio.Button value="BASIC" >BASIC</Radio.Button>
              <Radio.Button value="PREMIUM" >PREMIUM</Radio.Button>
            </Radio.Group>
          </Form.Item>

        <Form.Item
          label="PENDING UPGRADES"
        >
          {user.pending_upgrades.map((item, index)=>(
            <span key={index} >
              &nbsp;{item}&nbsp;{index+1 < user.pending_upgrades.length ? "," : ""}
            </span>
          ))}
        </Form.Item>
      </Form>

      <Form
        form={detailedCallsForm}
        hideRequiredMark
        onFinish={changeDetailedCalls}
        {...layout}
      >
        <Form.Item
          label="Detailed Calls"
          name="detailed_calls"
          rules={[{required: true, type: "number", min: 0}]}
          initialValue={user.detailed_calls}
        >
          <InputNumber
            disabled={isDisabled}
            onChange={(e)=> {
              if(e !== parseInt(e)) {
                setDetailedCallsChanged(false)
              } else {
                setDetailedCallsChanged(true)
              }
            }}
          />
        </Form.Item>
        {detailedCallsChanged ? (
          <Form.Item wrapperCol={{offset: 8,span: 16}}>
            <Button
              type="primary"
              htmlType="submit"
            >
              Confirm
            </Button>
          </Form.Item>
        ): null}
      </Form>

      <Form
        form={freeCallsForm}
        hideRequiredMark
        onFinish={changeFreeCalls}
        {...layout}
      >
        <Form.Item
          label="Free Calls"
          name="free_calls"
          rules={[{required: true, type: "number", min: 0}]}
          initialValue={user.free_calls}
        >
          <InputNumber
            disabled={isDisabled}
            onChange={(e)=> {
              if(e !== parseInt(e)) {
                setFreeCallsChanged(false)
              } else {
                setFreeCallsChanged(true)
              }
            }}
          />
        </Form.Item>
        {freeCallsChanged ? (
          <Form.Item wrapperCol={{offset: 8,span: 16}}>
            <Button
              type="primary"
              htmlType="submit"
            >
              Confirm
            </Button>
          </Form.Item>
        ): null}
      </Form>

      <Form
        {...layout}
        hideRequiredMark
      >
        {user.detailed_calls_expiry_time ? (
          <Form.Item
            label="Expiry Date"
          >
            <DatePicker
              disabled={isDisabled}
              selected={expiryDate}
              onChange={onDateChange}
              allowSameDay={true}
              dateFormat={'dd/MM/yyyy'}
            />
          </Form.Item>
        ) : null}

          <Form.Item
            label="Instagram Account"
          >
            {igUser && igUser.id ? (
              <Card style={{ width: 300, marginTop: 16 }}>
                <Meta
                  avatar={
                    <Avatar
                      src={igUser.profile_pic}
                    />
                  }
                  title={igUser.username}
                />
                <CloseCircleOutlined
                  className="cursor-pointer"
                  onClick={()=>setIgUser(null)}
                />
              </Card>
            ) : (
              <Input.Search
                onPressEnter={makeDetailedIDFromUsernames}
                onSearch={makeDetailedIDFromUsernames}
                value={query}
                placeholder="Enter Username"
                size="large"
                onChange={e=>setQuery(e.target.value)}
              />
            )}
          </Form.Item>
        <Form.Item
          label="Instagram Id"
        >
          {user.instagram_account && user.instagram_account.id ? (
            <InputNumber
              disabled={true}
              value={user.instagram_account.id}
            />
          ) : (
            <p>
              No Id
            </p>
          )}
        </Form.Item>
          <Form.Item label="Sign Up token">
            {user.signup_token && user.signup_token.claimedAt ?
              (
                <div>
                  Token Claimed At: {(user.signup_token.claimedAt.slice(0, 10))} <br />
                  Referrer: {user.signup_token.referrer} <br />
                  Value: {user.signup_token.token}
                </div>
              ) :
              <div>
                Token Not Claimed
                {user.signup_token ? (
                  <div>
                    Referrer: {user.signup_token.referrer} <br />
                    Value: {user.signup_token.token}
                  </div>
                ) : null}
              </div>
            }
          </Form.Item>
        </Form>

      <Form
        onFinish={changePassword}
        labelCol={{span: 8}}
        wrapperCol={{span: 10}}
        hideRequiredMark
        form={passwordForm}
      >
        <Form.Item
          name="password"
          label="Change Password"
          rules={[{
            required: true,
            min: 8,
            max: 24
          }]}
        >
          <Input
            placeholder="Enter Password here to Change"
            maxLength={24}
            minLength={8}
          />
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8,span: 16}} shouldUpdate={true}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
            >
              Change Password
            </Button>
          )}
        </Form.Item>
      </Form>

      <Form
        {...layout}
      >
        <Form.Item
          {...tailLayout}
        >
          <Button onClick={deleteAccountFunction} className="primary" danger>
            Delete Account
          </Button>
        </Form.Item>
      </Form>

      {jv_data.length ? (
        <>
          <Divider />
          <Title>
            JVZOO
          </Title>
          <Divider />
          <Table
            pagindation={false}
            bordered
            dataSource={jv_data}
            columns={tableCols1}
          />
          <Table
            pagindation={false}
            borered
            dataSource={jv_data}
            columns={tablecols2}
          />
        </>
      ) : (
        <Title level={3}>
          No Jvzoo Data
        </Title>
      )}

    </ContentWrapper>
  )
}

export default ShowUsers