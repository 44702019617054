import React, {useState} from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {Row, Col, Form, Input, Button, Checkbox, Switch, Typography, Select, Divider} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import ApiError from "../Common/Models/ApiError";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import SendToken from "../Common/ApiCall/SendToken";

const {Option} = Select;
const {Title} = Typography;

const ChangeUserPermissions = () => {
  const [permissionToken, setPermissionToken] = useState(null)

  function onFormFinish(values) {
    SendToken(values.email, values.is_premium_training, values.user_type)
      .then(r => {
        if(r.success) {
          setPermissionToken(r.data.token)
          SendNotification(NotificationTypeEnum.Success, 'Updated')
        }
        else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
        }
      })
  }

  return(
    <PlainContentWrapper>
      <Row align="center">
        <Col lg={{span: 20}} xs={{span: 24}}>
          <ContentWrapper marginLess>
            <Title>
              Change User Permissions
            </Title>
            <Divider />

            <Form
              onFinish={onFormFinish}
              initialValues={{
                user_type: 'normal',
                is_premium_training: false
              }}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[{
                  required: true,
                  type: 'email'
                }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="user_type"
                label="User Type"
              >
                <Select>
                  <Option value="normal">Normal</Option>
                  <Option value="pro">Pro</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="is_premium_training"
                label="Is Premium Training"
              >
                <Switch />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>

            {permissionToken ? (
              <Form.Item
                label="Permission Token"
              >
                <Input value={permissionToken} />
                <CopyToClipboard
                  text={permissionToken}
                  onCopy={()=>{
                    SendNotification(NotificationTypeEnum.Success, 'Copied to Clipboard')
                  }}
                >
                  <button>Token Copied to clipboard</button>
                </CopyToClipboard>
              </Form.Item>
            ) : null}

          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default ChangeUserPermissions