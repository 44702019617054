import React, {useEffect, useState} from "react";
import {AutoComplete, Divider, Form, Input, Typography} from "antd";
import ContentWrapper from "../Components/ContentWrapper";
import GetDetailedUser from "../Common/ApiCall/GetDetailedUser";
import EmailQuery from "../Common/ApiCall/EmailQuery";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";

const {Option} = AutoComplete
const {Title} = Typography
const {Search} = Input

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SelectUser = ({setData, setEmail, setKey}) => {
  const [result, setResult] = useState([])

  function handleSearch (value) {
    if(value.length<1) {
      return
    }
    EmailQuery(value)
      .then(r => {
        if(r.success) {
          setResult(r.data)
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
        }
      })
  }

  const EmailDetailedUserCall = (value) => {
    DetailedUserCall({email: value})
  }

  const TransactionIdUserCall = (value) => {
    DetailedUserCall({transactionId: value})
  }

  const DetailedUserCall = (value) => {
    GetDetailedUser(value)
      .then(r => {
        if(r.success) {
          setData(r.data)
          setKey(1)
          if(r.data.signup_token) {
            setEmail(r.data.signup_token.email)
          } else {
            setEmail(r.data.jvzoo_transactions[0].ccustemail)
          }
        } else {
          SendNotification(NotificationTypeEnum.Failure, r.errors[0].message)
        }
      })
  }

/*  useEffect(()=>{
    // For dev
    TransactionIdUserCall("234")
  }, [])*/

  return(
    <ContentWrapper>
      <Title level={2} >
        Search Email to Continue
      </Title>
      <Divider />
      <Form
        {...layout}
      >
        <Form.Item
          label="Email"
        >
          <AutoComplete
            onSelect={EmailDetailedUserCall}
            onSearch={handleSearch}
            placeholder="Enter Email here"
          >
            {result.map((email) => (
              <Option key={email} value={email}>
                {email}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
      </Form>

      <Divider>OR</Divider>

      <Title level={2} >
        Get User by Transaction Id
      </Title>
      <Divider />

      <Form
        {...layout}
      >
        <Form.Item
          label="Transaction Id"
          rules={[{required: true}]}
        >
          <Search
            onPressEnter={e=>TransactionIdUserCall(e.target.value)}
            onSearch={TransactionIdUserCall}
            enterButton="Search"
            placeholder={"Enter Transaction ID here"}
          />
        </Form.Item>
      </Form>

    </ContentWrapper>
  )
}

export default SelectUser