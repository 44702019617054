import React, {useEffect, useState} from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {Col, Row, Table} from "antd";
import SelectUser from "./SelectUser";
import ShowUsers from "./ShowUsers";

const UsersPanel = () => {
  const [data, setData] = useState(null)
  const [key, setKey] = useState(0)
  const [email, setEmail] = useState('')

  function getComponent() {
    switch (key) {
      case 0: return <SelectUser setKey={setKey} setEmail={setEmail} setData={setData} />
      case 1: return <ShowUsers email={email} setEmail={setEmail} setData={setData} user={data} setKey={setKey} />
    }
  }

  return(
    <PlainContentWrapper marginless>
      <Row align="center">
        <Col xs={{span: 24}} >
          {getComponent()}
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default UsersPanel