import React, {useEffect, useState} from "react";
import {Layout, Menu, Button} from "antd";
import logo from '../Common/Images/logo-full.png'
import {
  UserOutlined, HomeOutlined
} from '@ant-design/icons';
import HomePage from "./HomePage";
import isMobile from "is-mobile";
import ChangeUserPermissions from "./ChangeUserPermissions";
import logout from "../Common/ApiCall/Logout";
import UsersTable from "./UsersTable";
import UsersPanel from "./UsersPanel";
import ComingSoon from "./Coming Soon";

const {Header, Sider, Content} = Layout

const Items = [
  {
    key: 1,
    title: 'Home',
    icon: <HomeOutlined />
  },
  {
    key: 2,
    title: 'Change Users Permissions',
    icon: <UserOutlined />
  },
  {
    key: 3,
    title: 'Users Table'
  },
  {
    key: 4,
    title: "Users Panel"
  }
]

const Dashboard = () => {
  const [collapsed, toggleCollapsed] = useState(false)
  const [key, setKey] = useState(2) //default 2

  function toggle() {
    toggleCollapsed(!collapsed);
  }

  function toggleOnMobile() {
    if(isMobile()) {
      toggleCollapsed(true);
    }
  }

  function component() {
    switch (key) {
      case 1: return <HomePage setKey={setKey} />
      case 2: return <ChangeUserPermissions />
      case 3: return <UsersTable />
      case 4: return <UsersPanel />
      default: return <HomePage />
    }
  }

  useEffect(()=>toggleOnMobile(), [])

  return(
    <Layout className="is-fullheight">

      <Header className="header" style={{backgroundColor: 'white'}}>
        <img
          className="logo-img"
          alt="Logo"
          src={logo}
        />

        <Menu mode="horizontal" style={{float: 'right'}} >
          <Menu.Item key="3">
            <Button block onClick={()=>logout()} type="primary" className="dark-button">
              Log out
            </Button>
          </Menu.Item>
        </Menu>

      </Header>

      <Layout className="site-layout">

        <Sider collapsible collapsed={collapsed} onCollapse={toggle} >

          <Menu theme="dark" selectedKeys={String(key)} mode="inline" onClick={(key)=>setKey(parseInt(key.key))} defaultSelectedKeys={['1']}>
            {Items.map((item)=>(
              <Menu.Item key={item.key} icon={item.icon}>
                {item.title}
              </Menu.Item>
            ))}
          </Menu>

        </Sider>

        {component()}

      </Layout>

    </Layout>
  )
}

export default Dashboard