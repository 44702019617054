import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import authHeader from "./auth-header";
import {getPath} from "./api_url";

const SendToken = async (email, premium_training, user_type ) => {
  let apiResponse;
  if (process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(getPath("/send_token"), {
      email, products: ["BASE", premium_training? "PREMIUM_TRAINING" : "", user_type==='pro' ? "PREMIUM_EXTENSION" : ""].filter(Boolean)
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }

  if(apiResponse.body) {
    return apiResponse.body
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data":{},
    "success":true,
    "errors":[]
  }
}

export default SendToken
